<template>
  <aside class="left-aside" :style="{ display: show ? 'block' : 'none' }">
    <div class="left-aside-content">
      <div class="aside-seller">
        <div class="seller-name">
          <img width="17" height="17" src="/images/store.svg" alt="">
          {{currentPartner?.id ? currentPartner?.title || currentPartner?.slug : 'Все продавцы'}}
        </div>
      </div>
      <div class="aside-title">
        <h2>Категории товаров</h2>
        <div class="close-modal" @click.prevent="handleClose">
          <img width="20" height="20" src="/images/close.svg" alt="" />
        </div>
      </div>
      <ul class="catalog-menu">
        <li key="best">
          <NuxtLink :to="toParams('/')" class="catalog-menu-link" @click="bestDeal()">
            <img width="20" height="20" src="/images/sparkles.svg" alt="" />
            <span>Лучшие предложения</span>
          </NuxtLink>
        </li>

        <li v-for="cat in categories" :key="cat.id" :class="{ active: cat.collapsed }">
          <CategoryItem :cat="cat" @toggleSubcategories="toggleSubcategories" />
        </li>
      </ul>
    </div>
  </aside>
</template>

<script lang="ts">
import { useCityStore } from "~/store/cities";
import { useStore } from "~/store/index";
import { useModalStore } from "~/store/modal";
import { usePartnerStore } from "~/store/partners";
import type { Category, ModifiedCategory } from "~/types/types";

export default defineComponent({
  async setup() {
    const router = useRouter();
    const route = useRoute();
    const { routeParameters } = useQueryNavigation();
    const { category } = route.params;

    const store = {
      city: useCityStore(),
      main: useStore(),
      modal: useModalStore(),
      partner: usePartnerStore()
    };
    const { params } = storeToRefs(store.main);
    const { current : currentPartner, partnerId } = storeToRefs(store.partner);

    const isMobile = ref(false);

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 767.98;
    };

    onMounted(() => {
      setCategories();
      if (process.client && typeof window != undefined) {
        window.addEventListener("resize", updateIsMobile);
        updateIsMobile();
      }
    });
    
    onUnmounted(() => {
      if (process.client && typeof window != undefined) {
        window.removeEventListener("resize", updateIsMobile);
      }
    });

    const { data, refresh } = await useAsyncData<Category[]>("categories", async () =>
      await store.main.fetchCategories(),
      // { watch: [partnerId] }
    );
    const categories = ref<ModifiedCategory[]>([]);
    const setCategories = () => {
      categories.value = [];

      if (data.value) {
        categories.value = data.value.map((cat) => ({
          ...cat,
          collapsed: cat.slug === category ? true : false,
          childrenSlugs: (cat.children || []).map((child) => child.slug || ""),
        }));

        categories.value.forEach((cat) => {
          if (
            !cat.collapsed &&
            cat.childrenSlugs.some((slug) => slug === category)
          ) {
            cat.collapsed = true;
          }
        });
      }
    };

    // modal status
    const show = computed(() => {
      return store.modal.mobileModal.catalog;
    });

    // methods
    const collapseAll = () => {
      categories.value.forEach((cat) => {
        cat.collapsed = false;
      });
    };
    const toggleSubcategories = (cat: ModifiedCategory) => {
      if (!cat.collapsed) {
        collapseAll();
      }
      const foundCat = categories.value.find((c) => c.id === cat.id);
      if (foundCat) {
        if (params.value.search) {
          store.main.updateParams({
            search: null,
          });
        }

        if (!foundCat.collapsed) {
          if(!isMobile.value) {
            router.push(routeParameters(`/${foundCat.slug}`));
          }
        }
        
        foundCat.collapsed = !foundCat.collapsed;
      }
    };
    

    const bestDeal = () => {
      clearSearch();
      if(isMobile.value) {
        handleClose();        
      }
    }

    const handleClose = () => {
      store.modal.closeMobileModal("catalog");
    };

    const clearSearch = () => {
      if (params.value.search) {
        store.main.updateParams({
          search: null,
        });
      }
    };

    // watchers
    watch(
      () => route.path,
      (path) => {
        if (path === "/") collapseAll();
      }
    );

    // feat (при обновлении города, обновляем список категории)
    const { cityId } = storeToRefs(store.main);
    watch(
      () => cityId.value,
      async (id: string | null, prevId: string | null) => {
        if (id !== prevId) {
          store.main.clearCategories();

          await refresh();
          setCategories();
        }
      }
    );
    // feat (если менялся партнер, обновляем категории)
    watch(
      () => partnerId.value,
      async (id: string | null, prevId: string | null) => {
        if (id !== prevId) {
          store.main.clearCategories();

          await refresh();
          setCategories();
        }
      }
    );

    return {
      categories,
      currentPartner,
      show,

      collapseAll,
      toggleSubcategories,
      handleClose,
      clearSearch,
      bestDeal,
      isMobile,
      toParams: routeParameters,
    };
  },
});
</script>

<style scoped>
.no-icon {
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.catalog-menu-link {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
</style>
