<script lang="ts" setup>
const scrollUp = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});

const handleScroll = () => {
  const scrollY = window.scrollY || window.pageYOffset;
  let scrollID: HTMLElement | null = document.querySelector('.scrollUp');
  
  if (scrollID) {
    if (scrollY > (window.innerHeight / 3)) {
      scrollID.style.transform = 'scale(1)';
    } else {
      scrollID.style.transform = 'scale(0)';
    }
  }
};
</script>

<template>
    <img class="scrollUp" src="/images/up.svg" width="45px" height="45px" alt="" @click="scrollUp()"/>
</template>

<style>
.scrollUp {
    cursor: pointer;
    position: fixed;
    z-index: 80;
    display: inline-flex;
    align-items: center;
    right: 1%;
    bottom: 30px;
    border-radius: 11px 0px 0px 11px;
    color: #fff;
    transform: scale(0);
    transition: transform 0.3s ease;
}
</style>