<script lang="ts" setup>
const props = defineProps<{
  src: string;
  srcset: string;
  scales: { min: number; max: number };
  sizes?: string;
  width?: number;
  height?: number;
  preload?: boolean;
  alt?: string;
  index: Number;
}>();

const emit = defineEmits(["load", "error"]);

// Мобилка: media
const isMobile = ref(false);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 767.98;
};

onMounted(async () => {
    updateIsMobile();
    window.addEventListener("resize", updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener("resize", updateIsMobile);
});

const currentScale = ref(1);
let transformOrigin = ref("center center");
const scaleFromButtons = ref(false);

const zoomStyle = computed(() => ({
  transform: `scale(${currentScale.value})`,
  // width: `${currentScale.value * 100}%`,
  // height: `${currentScale.value * 100}%`,
  transformOrigin: transformOrigin.value,
}));

const onLoad = (event: Event) => {
  emit("load", event);
};

const onError = () => {
  emit("error");
};

const onMove = (e: MouseEvent | TouchEvent) => {
  if(!isMobile.value) {
    const zoomer = e.currentTarget as HTMLElement;
    const { left, top, width, height } = zoomer.getBoundingClientRect();

    let offsetX, offsetY;
    if (e instanceof MouseEvent) {
      offsetX = e.clientX - left;
      offsetY = e.clientY - top;
    } 

    const percentX = (offsetX / width) * 100;
    const percentY = (offsetY / height) * 100;

    transformOrigin.value = `${percentX}% ${percentY}%`;
    zoomStyle.value.transformOrigin = transformOrigin.value;
  }
};

const onMouseLeave = () => {
  scaleFromButtons.value = false;
  currentScale.value = 1;
};

const increaseScale = () => {
  if (currentScale.value + 1 <= (props.scales.max || 2)) {
    currentScale.value += 1;
    scaleFromButtons.value = true;
  }
};

const decreaseScale = () => {
  if (currentScale.value - 1 >= (props.scales.min || 1)) {
    currentScale.value -= 1;
    scaleFromButtons.value = true;
  }
};

const isIncreaseDisabled = computed(() => currentScale.value + 1 > (props.scales.max || 2));
const isDecreaseDisabled = computed(() => currentScale.value - 1 < (props.scales.min || 1));
</script>

<template>
  <div
    class="image__zoomer"
    :class="[{'image__zoomer-is_mobile': isMobile, 'image__zoomer-crosshair': currentScale > 1}]"
    @mousemove="onMove"
    @mouseleave="onMouseLeave"
    @touchmove="onMove">

    <nuxt-img
      class="image__zoomer-photo"
      :src="src"
      :srcset="srcset"
      :sizes="sizes"
      :width="width"
      :height="height"
      :preload="preload"
      :style="zoomStyle"
      :alt="alt"
      @load="onLoad"
      @error="onError"
    />

    <div class="image__zoomer__editing">
        <button class="image__zoomer__editing-box" @click="increaseScale" :class="{'disabled': isIncreaseDisabled}">
            <img src="/images/search/plus.svg"/>
        </button>

        <button class="image__zoomer__editing-box" @click="decreaseScale" :class="{'disabled': isDecreaseDisabled}" v-if="!isDecreaseDisabled">
            <img src="/images/search/minus.svg"/>
        </button>
    </div>
  </div>
</template>

<style lang="less" scoped>
.image__zoomer {
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: default;
  border-radius: 11px;
  &-is_mobile {
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    .image__zoomer-photo {
      transform-origin: top left !important;
      transition: all 0.3s ease !important;
    }
  }
  &-crosshair {
    cursor: crosshair;
  }
  &-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
    position: absolute;
    -webkit-user-drag: none;
    user-select: none;
  }
  &-disable {
    opacity: 0;
  }
  &__editing {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    z-index: 2;
    top: 10px;
    right: 10px;
    &-box {
        cursor: pointer;
        width: 30px;
        padding: 0;
        border: none;
        aspect-ratio: 1;
        box-shadow: 0 .0625rem .125rem .0625rem #00000026;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        img {
            overflow: inherit;
            width: 60%;
            height: 60%;
            object-fit: contain;
            -webkit-user-drag: none;
            user-select: none;
        }
    }
    .disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: visible;
    }
  }
}
</style>