<script lang="ts" setup>
import { useModalStore } from "~/store/modal";

const handleOpen = () => {
    useModalStore().openMobileModal("catalog");
}
</script>

<template>
    <div class="mobile-catalog">
        <a href="#" class="btn" @click.prevent="handleOpen">
        <img width="20" height="20" src="/images/layout-list.svg" alt="" />
        Категории товаров
        </a>
    </div>
</template>

<style>
    
</style>