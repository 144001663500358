<template>
  <header class="header">
    <div class="header-content">
      <div class="header-logo">
        <a href="/" class="header-logo-image" @click="clearFilter">
          <img width="50" height="37" src="/images/express-logo.svg" alt="" />
        </a>
        <div class="header-logo-text">
          <a href="/" @click="clearFilter">
            <div class="image">
              <img
                width="93"
                height="36"
                src="/images/express-text.svg"
                alt=""
              />
            </div>
          </a>
          <div class="text">
            Продукты с доставкой
            <template v-if="selectedCity?.name">
              в
              <b>{{ declineCity(selectedCity.name) }}</b>
            </template>
          </div>
        </div>
      </div>
      <div class="place-choose" @mouseleave="showCatalog = false">
        <template v-if="isMobile">
          <div class="place-choose-toggle" @click.prevent="openModal('city')">
            <img width="20" height="20" src="/images/map-pin.svg" alt="" />
            <span>
              {{ selectedCity?.name }}
            </span>
          </div>
        </template>
        <template v-else>
          <div class="place-choose-toggle" @mouseover="showCatalog = true">
            <img width="20" height="20" src="/images/map-pin.svg" alt="" />
            <span>
              {{ selectedCity?.name }}
            </span>
          </div>
          <div
            v-if="cities?.length"
            class="place-choose-podmenu"
            :class="{ active: showCatalog }"
          >
            <a
              v-for="city in cities"
              :key="city.id"
              href="#"
              :class="{ active: city.id === selectedCity.id }"
              @click.prevent="handleCityChoise(city)"
            >
              {{ city.name }}
            </a>
          </div>
        </template>
      </div>
      <template v-if="isAuthorized">
        <div class="header-nav">
          <a
            v-if="isAdmin"
            href="#"
            class="header-user"
            :class="{ disabled: loading }"
            @click.prevent="openModal('admin')"
          >
            <img width="20" height="20" src="/images/settings.svg" alt="" />
            <client-only>
              <span>Админ</span>
            </client-only>
            <i v-if="totalCount" class="count">
              {{ totalCount }}
            </i>
          </a>
          <a
            href="#"
            class="header-user"
            :class="{ disabled: loading }"
            @click.prevent="openModal('profile')"
          >
            <img width="20" height="20" src="/images/user.svg" alt="" />
            <client-only>
              <span>{{ user.firstName || "Профиль" }}</span>
            </client-only>
            <i v-if="user.actionsCount" class="count">
              {{ user.actionsCount }}
            </i>
          </a>
        </div>
      </template>
      <a
        v-else
        href="#"
        class="header-user"
        :class="{ disabled: loading }"
        @click.prevent="openModal('signin')"
      >
        <img width="20" height="20" src="/images/user.svg" alt="" />
        <client-only>
          <span>Вход</span>
        </client-only>
      </a>
    </div>
    
    <!-- Телепорты -->
    <div class="header-teleported">
      <ProductsMobileCatelogButton />
      <div id="common-search-bar-teleport-mobile" class="search-form-sticky" v-if="isMobile"></div>
    </div>
  </header>
</template>

<script lang="ts">
import { useStore } from "~/store";
import { useAdminStore } from "~/store/admin";
import { useAuthStore } from "~/store/auth";
import { useCityStore } from "~/store/cities";
import { useModalStore } from "~/store/modal";
import { usePartnerStore } from "~/store/partners";
import { useProfileStore } from "~/store/profile";

import type { City } from "~/types/types";

export default defineComponent({
  setup() {
    const store = {
      admin: useAdminStore(),
      auth: useAuthStore(),
      city: useCityStore(),
      main: useStore(),
      modal: useModalStore(),
      partner: usePartnerStore(),
      profile: useProfileStore(),
    };

    const { user, loading } = storeToRefs(store.profile);
    const { isAdmin, totalCount } = storeToRefs(store.admin);
    const { isAuthorized } = storeToRefs(store.auth);
    const { city: selectedCity, cities } = storeToRefs(store.city);

    const showCatalog = ref<boolean>(false);
    const clearFilter = () => {
      store.main.clearParams();
      store.main.params.cityId = selectedCity.value?.id || null;
    };

    // cities
    const handleCityChoise = (city: City) => {
      store.city.saveCityToLocalStorage(city);
      store.city.setConfirmedCityToLocalStorage(!!city);

      store.partner.clearData();
      
      showCatalog.value = false;
    };

    // modal
    const { openModal } = store.modal;

    // media
    const isMobile = ref(false);
    let scrollListener: (() => void) | null = null; 
    let header: HTMLElement | null = null;

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 767.98;
      if (isMobile.value) {
        addScrollListener();
      } else {
        removeScrollListener();
        header?.classList.remove('Before');
      }
    };

    const addScrollListener = () => {
      if (!scrollListener) {
        scrollListener = () => {
          if (window.scrollY > 0) {
            header?.classList.add('Before');
          } else {
            header?.classList.remove('Before');
          }
        };
        window.addEventListener('scroll', scrollListener);
      }
    };

    const removeScrollListener = () => {
      if (scrollListener) {
        window.removeEventListener('scroll', scrollListener);
        scrollListener = null;
      }
    };

    onMounted(async () => {
      header = document.querySelector('.header-content');
      updateIsMobile();
      window.addEventListener("resize", updateIsMobile);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", updateIsMobile);
      removeScrollListener();
    });

    const { declineCity } = useDeclineCity();

    return {
      showCatalog,
      clearFilter,

      // auth/user
      isAdmin,
      totalCount,
      isAuthorized,
      user,
      loading,

      // cities
      cities,
      selectedCity,
      handleCityChoise,

      // modal
      openModal,

      isMobile,

      declineCity,
    };
  },
});
</script>

<style lang="less" scoped>
.header {
  z-index: 5;

  &-nav {
    display: flex;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-right: 1rem;
        @media (max-width: 576.98px) {
          margin-right: 0.25rem;
        }
      }
    }
  }
}

.header-content.Before {
  padding: 0px 20px;
  overflow: hidden;
  max-height: 0;
}
</style>